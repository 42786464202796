import React, { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

interface HreflangLinksProps {
  locales: string[]
}

const HreflangLinks: React.FC<HreflangLinksProps> = ({ locales }) => {
  const router = useRouter()
  const { asPath, defaultLocale } = router

  const uniqueLocales = Array.from(
    new Set(locales.map((loc) => loc.split('-')[0]))
  )
    .map((uniqueLoc) => locales.find((loc) => loc.startsWith(uniqueLoc)))
    .filter((loc): loc is string => !!loc)

  const [currentOrigin, setCurrentOrigin] = useState<string | null>(null)

  const defaultLang = useMemo(
    () => uniqueLocales.find((lang) => lang.includes(defaultLocale)),
    [defaultLocale, uniqueLocales]
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentOrigin(window.location.origin)
    }
  }, [])

  if (!currentOrigin || locales.length === 0 || !defaultLang) return null

  const hreflangLinks: {
    href: string
    hreflang: string
  }[] = []
  locales.forEach((loc) => {
    const isDefault = loc === defaultLang
    if (isDefault) {
      hreflangLinks.push({
        href: `${currentOrigin}${asPath}`,
        hreflang: 'x-default',
      })
    }
    hreflangLinks.push({
      href: `${currentOrigin}/${loc.split('-')[0]}${asPath}`,
      hreflang: loc,
    })
  })

  return (
    <Head>
      {hreflangLinks.map((link, index) => (
        <link
          key={index}
          rel="alternate"
          href={link.href}
          hrefLang={link.hreflang}
        />
      ))}
    </Head>
  )
}

export default HreflangLinks
